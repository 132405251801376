import React, { useEffect, useState } from "react"
import style from "../../style/SaleGeneric.module.css"
import { GetSmallModels } from "../../Models/GetModels"
import {
    faBarcode, faCalculator, faCamera, faCheck, faClose, faDatabase, faFile, faGear, faHandHoldingDollar, faKeyboard, faLock, faMagnifyingGlass,
    faMoneyBill1Wave,
    faMoneyBillAlt,
    faMoneyBillTransfer,
    faPlus,
    faRemove,
    faSave, faTrash, faUserLargeSlash, faWarning,
} from "@fortawesome/free-solid-svg-icons"
import InputModels from "../../Models/InputModels.js"
import { TitlePageResponsiveIcon } from "../../layout/TitlePage.js"
import FindWithBarcode from "../FindWithBarcode.js"
import Loader from "../../layout/Loader.js"
import InputModelsBlock from "../../layout/InputModelsBlock.js"
import GetExpandedImage from "../../layout/GetExpandedImage.js"
import ItemModelList from "../../Models/ItemModelList.js"
import ConfirmationPopup from "../../Models/ConfirmationPopup.js"
import Alert from "../../Models/Alert.js"
import GetAmountList from "../../Models/GetAmountList.js"
import { faPix, faProductHunt } from "@fortawesome/free-brands-svg-icons"
import Space from "../../layout/Space.js"
import { GetButton, GetButtonIcon } from "../../layout/ButtonsLayout.js"
import GetConfigureSale from "./GetConfigureSale.js"
import { auxSale, databaseSettings, listItemsMemory, listProductsMemory, myStoreDataMemory, updateFromSale, updateIfNull } from "../../backEnd/Memory.js"
import { useError, useLoading } from "../../AppContext.js"
import ProductModelList from "../../Models/ProductModelList.js"
import ItemsSale from "../../objects/ItemBJ/ItemsSale.js"
import GenericProduct from "../../objects/Brands/Products/GenericProducts.js"
import ClientsModels from "../../Models/ClientsModels.js"
import { setSaveSale } from "../../controllers/CloudSales.js"
import ViewSaleController, { AddPayment } from "./ShowSale/ViewSaleController.js"
import cloneDeep from 'lodash/cloneDeep';
import { employeeData } from "../../backEnd/InitController.js"
import GetBudget from "../../layout/GetBudget.js"
import { requestPDFs } from "../../controllers/CloudPdfs.js"
import Barcode from "../Barcode.js"
import GetPix from "../../Models/GetPix.js"

function SaleGeneric({isEdite,saleEdite}) {

    const [IsEditeSale] = useState(()=>{
        if(isEdite){
            auxSale.configureEditeSale(saleEdite)
        }
        return isEdite?? false
    })

    const [initialized,setInitialized] = useState(false)
    const [showConfigureSale, setShowConfigureSale] = useState(false)
    const [canCreateSale] = useState(employeeData().employeePermission.permissionsSales[0])

    const [showAlertSave, setShowAlertSave] = useState(false)
    const [showConfirmSave, setShowConfirmSave] = useState(false)
    const [showViewAndConfirmeSale, setShowViewAndConfirmeSale] = useState(false)
    const [titleConfirme, setTitleConfirme] = useState('')
    const [legendConfirme, setLegendConfirme] = useState('')
    const [isRestarSale, setIsRestartSale] = useState(false)
    const [showFindBarcode, setShowFindBarcode] = useState(false)

    const [filteredItemBJ, setFilteredItemBJ] = useState([])
    const [itemBJ, setItemBJ] = useState([])

    const [filteredProduct, setFilteredProduct] = useState([])
    const [product, setProduct] = useState([])
    const [isBJ, setIsBJ] = useState(true)

    const { setIsLoading } = useLoading()
    const { showError } = useError()

    const [loading, setLoading] = useState(false)

    const [isExpanded, setIsExpanded] = useState(false)
    const [nameProduct, setNameProduct] = useState('')
    const [linkImage, setSetLinkImage] = useState('')

    const [inputBarcode, setInputbarcode] = useState('')
    const [statusFindWithCode, setStatusFindWithCode] = useState(false)

    const [showBudget, setShowBudget] = useState(false)
    const [showViewSale, setShowViewSale] = useState(false)
    const [saleSelected, setSaleSelected] = useState(null)

    useEffect(() => {
        if (canCreateSale) {
            async function fetchData() {
                setIsLoading(true)
                setLoading(true)
                if(await updateIfNull(showError) === false)
                    await updateFromSale(showError)
                auxSale.updateValues(itemBJ, product)
                setFilteredItemBJ(auxSale.listItems)
                setItemBJ(auxSale.listItems)
                setProduct(auxSale.listProducts)
                setInitialized(true)
                setFilteredProduct(auxSale.listProducts)
                setLoading(false)
                setIsLoading(false)
            }
            fetchData()
        }
    }, [])

    useEffect(() => {  
        handleChangeFilter()
        handleChangeFilterProduct()
        auxSale.updateValues(itemBJ, product)
    }, [itemBJ, product])

    const toggleImageExpansion = (nameProduct, linkImage) => {
        setIsExpanded(!isExpanded)
        setNameProduct(nameProduct)
        setSetLinkImage(linkImage)
    }

    const handleChangeFilter = (value) => {
        const data = [...itemBJ]
        if (value != null) {
            const filteredStock = data.filter(
                stock => {
                    const filternName = stock.itemGlobal.name.toLowerCase().includes(value.target.value.toLowerCase())
                    return filternName
                }
            )
            setFilteredItemBJ(filteredStock)
        } else {
            setFilteredItemBJ(data)
        }
    }

    const handleChangeFilterProduct = (value) => {
        const data = [...product]
        if (value != null) {
            const filteredStock = data.filter(
                stock => {
                    const filternName = stock.nameProduct.toLowerCase().includes(value.target.value.toLowerCase())
                    return filternName
                }
            )
            setFilteredProduct(filteredStock)
        } else {
            setFilteredProduct(data)
        }
    }

    const handleOpenFindWithCode = (isBJ) => {
        setIsBJ(isBJ)
        setStatusFindWithCode(true)
    }

    const onRemove = (itemToRemove, isBJ) => {
        if (isBJ) {
            const temp = itemBJ.filter(item => item !== itemToRemove)
            setItemBJ(temp)
            setFilteredItemBJ(temp)
        } else {
            const temp = product.filter(item => item !== itemToRemove)
            setProduct(temp)
            setFilteredProduct(temp)
        }
    }

    const onLess = (data, isBJ) => {
        data.amount--
        if (data.amount <= 0)
            onRemove(data, isBJ)
        else isBJ ? handleChangeFilter() : handleChangeFilterProduct()

        auxSale.updateValues(itemBJ, product)
    }

    const onValidator = (item, valueSun, isBjForced) => {
        var itemTemp = []
        var temp = []

        let boolValue = isBjForced ?? isBJ

        if (boolValue) {
            const foundIndex = itemBJ.findIndex(element => element.itemGlobal.codebar === item.itemGlobal.codebar)
            temp = []
            if (foundIndex !== -1) {
                const indexMemory = listItemsMemory.findIndex(ss => ss.itemGlobal.codebar === item.itemGlobal.codebar)
                if (listItemsMemory[indexMemory].amount - (itemBJ[foundIndex].amount + valueSun) < 0) {
                    showError('ITEM SEM ESTOQUE', 'Não há Estoque Suficiente desse Modelo')
                    return false
                }
                temp = [...itemBJ]
                temp[foundIndex].amount += valueSun
            } else {
                if (item.amount <= 0) {
                    showError('ITEM SEM ESTOQUE', 'Esse Modelo de Peça Está com o Estoque Zerado')
                    return false
                }
                itemTemp = new ItemsSale()
                itemTemp.fromOther(item)
                itemTemp.amount = valueSun
                temp = [...itemBJ, itemTemp]
            }
            auxSale.listItems = temp
            setItemBJ(auxSale.listItems)
            setFilteredItemBJ(auxSale.listItems)
            handleChangeFilter()
            return true
        } else {
            const foundIndex = product.findIndex(element => element.idGenericProducts === item.idGenericProducts)
            temp = []
            if (foundIndex !== -1) {
                const indexMemory = listProductsMemory.findIndex(ss => ss.idGenericProducts === item.idGenericProducts)
                if (listProductsMemory[indexMemory].amount - (product[foundIndex].amount + valueSun) < 0) {
                    showError('PRODUTO SEM ESTOQUE', 'Não há Estoque Suficiente desse Modelo')
                    return false
                }
                temp = [...product]
                temp[foundIndex].amount += valueSun
            } else {
                if (item.amount <= 0) {
                    showError('PRODUTO SEM ESTOQUE', 'Esse Modelo Está com o Estoque Zerado')
                    return false
                }
                itemTemp = new GenericProduct()
                itemTemp.fromOtherWithAmount(valueSun, item)
                temp = [...product, itemTemp]
            }
            auxSale.listProducts = temp
            setProduct(auxSale.listProducts)
            setFilteredProduct(auxSale.listProducts)
            handleChangeFilterProduct()
            return true
        }

    }

    const handleConfirmeSave = () => {
        setIsRestartSale(false)
        setTitleConfirme('FINALIZAR VENDA E SALVAR')
        setLegendConfirme('Tem Certeza que Deseja Realizar Essa Venda')
        setShowConfirmSave(true)
    }

    const handleOpenViewSale = (sale) => {
        setSaleSelected(sale)
        setShowViewSale(true)
    }

    const handleSave = async () => {
        setShowViewAndConfirmeSale(false)
        setShowConfirmSave(false)
        setIsLoading(true)
        setLoading(true)

        auxSale.configureSaleToSave()
        const results = await setSaveSale(auxSale.sale, showError)
        if (results.status === 200) {
            auxSale.sale.idSaleCloud = results.data.idSale
            handleOpenViewSale(cloneDeep(auxSale.sale))
            handleRestartSaleNow()   
        }
        setLoading(false)
        setIsLoading(false)
    }

    const handlerCameraBarcode = (valueBarcode) =>{
        var indexItem = listItemsMemory.findIndex(element => element.itemGlobal.codebar.toString() === valueBarcode)
        if (indexItem !== -1) {
            if(onValidator(listItemsMemory[indexItem], 1, true)){
                return listItemsMemory[indexItem]
            }
        } else {
            indexItem = listProductsMemory.findIndex(element => (element.codebar.toString() === valueBarcode))
            if (indexItem !== -1) {
                if(onValidator(listProductsMemory[indexItem], 1, false)){
                    return listProductsMemory[indexItem]
                }
            } else {
                showError("CÓDIGO DE BARRAS INVÁLIDO", "Não Há Produtos com Esse Código de Barras")
                setShowFindBarcode(false)
                return null
            }
        }

        return null
    } 

    const handleReadbarcode = (e) => {
        setInputbarcode(e.target.value)
        if (e.target.value.length >= 13) {
            var indexItem = listItemsMemory.findIndex(element => element.itemGlobal.codebar === e.target.value)
            if (indexItem !== -1) {
                onValidator(listItemsMemory[indexItem], 1, true)
            } else {
                indexItem = listProductsMemory.findIndex(element => (element.codebar === e.target.value && element.codebar !== ''))
                if (indexItem !== -1) {
                    onValidator(listProductsMemory[indexItem], 1, false)
                } else {
                    showError("CÓDIGO DE BARRAS INVÁLIDO", "Não Há Produtos com Esse Código de Barras")
                    e.target.blur()
                }
            }
            setInputbarcode('')
        }
    }

    const handleRestartSale = () => {
        setIsRestartSale(true)
        setTitleConfirme('REINICIAR VENDA')
        setLegendConfirme('Tem Certeza que Deseja Limpar os Dados e Reiniciar a Venda')
        setShowConfirmSave(true)
    }

    const handleRestartSaleNow = async () => {
        auxSale.resetAuxSale()
        setShowConfirmSave(false)
        setLoading(true)
        setItemBJ([])
        setProduct([])
        await updateFromSale(showError)
        setLoading(false)
    }

    const handleGenerateBudget = async(formData) =>{
        setLoading(true)
        setIsLoading(true)
        auxSale.configureSaleToSave()
        await requestPDFs({
            'type':'pdfBudget',
            'validate':new Date(formData.validate),
            'condition':formData.condition,
            'seller': employeeData().genericPerson.name,
            'client': auxSale.client._genericPerson.name,
            'sale': auxSale.sale.getMapFromCloud(),
            'myStore': myStoreDataMemory.getMapFromCloud()
          },showError)
        setIsLoading(false)
        setLoading(false)
    }

    return (
        <div className={style.containerCenter}>
            {canCreateSale ? <>
                {!loading ?
                    <div className={style.divController}>
                        {filteredItemBJ.length > 0 || filteredProduct.length > 0 ?
                        <>
                        <div className={style.div}>
                            {(window.innerWidth > 1200 || filteredItemBJ.length > 0) && databaseSettings.isCanUseBrunaBJ?
                            <div className={style.divList}>
                                <div className={style.row}>
                                    <GetSmallModels title={'Lista de Bruna Semijoias na Venda'}
                                    subtitle={'Pré Visualização das Peças'}
                                        icon={faDatabase} />
                                    <Space left={5} />
                                    <div className={style.inputFilter}>
                                        <InputModels iconButton={faMagnifyingGlass} type={"number"}
                                            placeholder={"Buscar Peças na Lista Abaixo..."} maxLength={10}
                                            onChange={handleChangeFilter} />
                                    </div>
                                </div>
                                <div className={style.buildList}>
                                    <GetAmountList amount={filteredItemBJ.length} title={'Peças da Bruna Semijoias na Venda...'} />
                                    {filteredItemBJ.map((item, index) => (
                                        <ItemModelList
                                            data={item}
                                            valueMultiplay={auxSale.factor}
                                            showValueSale={true}
                                            onPlus={() => onValidator(item, 1, true)}
                                            onLess={() => onLess(item, true)}
                                            onRemove={() => onRemove(item, true)} fucntionButton={() => { }} functionImage={
                                                () => toggleImageExpansion(item.itemGlobal.name, item.getImage())} />
                                    ))}
                                </div>
                            </div> : null
                            }
                            {window.innerWidth > 1200 || filteredProduct.length > 0?
                            <div className={style.divList}>
                                <div className={style.row}>
                                    <GetSmallModels subtitle={'Pré Visualização dos Produtos'} title={'Lista de Produtos na Venda'} icon={faProductHunt} />
                                    <Space left={5} />
                                    <div className={style.inputFilter}>
                                        <InputModels iconButton={faMagnifyingGlass} type={"text"}
                                            placeholder={"Buscar Produtos na Lista Abaixo..."} maxLength={50}
                                            onChange={handleChangeFilterProduct} />
                                    </div>
                                </div>
                                <div className={style.buildList}>
                                    <GetAmountList amount={filteredProduct.length} title={'Produtos Genéricos na Venda...'} />
                                    {filteredProduct.map((data, index) => (
                                        <ProductModelList
                                            showValueSale={true}
                                            onPlus={() => onValidator(data, 1, false)}
                                            onLess={() => onLess(data, false)}
                                            onRemove={() => onRemove(data, false)} data={data} fucntionButton={() => { }} functionImage={
                                                () => toggleImageExpansion(data.nameProduct, data.linkImage)} />
                                    ))}
                                </div>
                            </div>: null
                            }
                        </div>
                        </>:null}
                        <div className={filteredItemBJ.length > 0 || filteredProduct.length > 0 ? style.data : style.allSize}>
                            <div className={style.div}>
                                <div className={style.row}>
                                    {window.innerWidth < 1200 ?
                                        <GetButton  title={'CONFIGURAR NOVA VENDA'} color={'#ff0000'} handleFunction={() => setShowConfigureSale(true)} icon={faGear} />
                                    :
                                    <TitlePageResponsiveIcon title={'DETALHES DA VENDA'}
                                      children={
                                        <GetButton responsive={true} title={'CONFIGURAR NOVA VENDA'} color={'#ff0000'} handleFunction={() => setShowConfigureSale(true)} icon={faGear} />
                                      }  
                                        path={'É uma Breve Contabilidade da Venda'} icon={faCalculator} />
                                    }
                                </div>
                                {initialized?
                                <>
                                    { auxSale.canShowValues()? 
                                    <div className={style.divData}>
                                      <GetData value={[...itemBJ]}/>
                                    </div>
                                    :null
                                    }
                                </>:null
                                }
                            </div>
                            <Space top={5}/>
                            <div className={style.div}>
                                <div className={style.row}>
                                    <div className={style.inputInserItems}>
                                        <InputModelsBlock legend={'LEITOR / INSIRA O CÓDIGO DE BARRAS'} iconButton={faBarcode} type={"number"}
                                            value={inputBarcode}
                                            placeholder={" Digite o Código de Barras..."} maxLength={13}
                                            onChange={handleReadbarcode} />
                                    </div>
                                    <div className={style.divButtons}>
                                        {window.innerWidth < 1200 ? <GetButton title={'LER CÓDIGO DE BARRAS COM CAMERA'} icon={faCamera} height={50} color='#016053' handleFunction={() => setShowFindBarcode(true)} /> : null}
                                        <Space left={5} top={5}/>
                                        <GetButton title={'ADICIONAR PRODUTOS PELO NOME'} icon={faKeyboard} height={50} color='#016053' handleFunction={() => handleOpenFindWithCode(false)} />
                                        {databaseSettings.isCanUseBrunaBJ?
                                            <>
                                            <Space left={5} top={5}/>
                                            <GetButton title={'ADICIONAR BRUNA SEMIJOIAS PELO NOME'} icon={faKeyboard} height={50} color='#007bff' handleFunction={() => handleOpenFindWithCode(true)} />
                                            </>: null}
                                    </div>
                                </div>
                            </div>
                            {window.innerWidth <= 1200 ? <Space top={5}/> :null }
                            {initialized?
                            <>
                                {
                                    auxSale.canShowValues()?
                                    <div className={style.div}>
                                        <div className={style.row}>
                                            <GetButton title={IsEditeSale? 'FINALIZAR E EDITAR AGORA':'FINALIZAR E SALVAR A VENDA'} icon={faSave} handleFunction={() => setShowViewAndConfirmeSale(true)} color='#166001' height={50} />
                                            <Space left={5} top={5}/>
                                            {!IsEditeSale?
                                                <>
                                                    <GetButton title={'GERAR ORÇAMENTO'} icon={faFile} handleFunction={() => setShowBudget(true)} color='#0390fc' height={50} />
                                                    <Space left={5} top={5}/>
                                                    <GetButton title={'REINICIAR VENDA'} icon={faTrash} handleFunction={handleRestartSale} color='#ff0000' height={50} />
                                                </>:null
                                            }
                                        </div>
                                    </div>
                                :null}
                            </>
                            :null}
                        </div>
                    </div>: 
                    <div className={style.div}>
                        <Loader titleFinding={'Estabelecendo Comunicação com o Servidor'}
                        subtitleFinding={'Aguarde...'} loading={loading} />
                    </div>}
            </> : 
            <div className={style.div}>
                <GetSmallModels icon={faLock} title={'ACESSO NEGADO'}
                subtitle={'Você Não Tem Permissão para Efetuar uma Venda'} />
            </div>
            }
            {showFindBarcode ? <Barcode onClose={()=>setShowFindBarcode(false)} onValidate={handlerCameraBarcode}/>: null}
            {showBudget ? <GetBudget onGenerate={handleGenerateBudget} containerData={GetDetailsConfirme} onClose={()=>setShowBudget(false)}/> : null }
            {showViewSale ? <ViewSaleController cantEditeDescription={false} handleSave={() => { }} saleStart={saleSelected} handleClose={() => setShowViewSale(false)} /> : null}
            {showConfigureSale ? <GetConfigureSale isEdite={IsEditeSale} onClose={() => setShowConfigureSale(false)} /> : null}
            {showAlertSave ? <Alert top={100} message={"Estoque Atualizado com Sucesso"} onClose={() => setShowAlertSave(false)} type={"success"} /> : null}
            {showViewAndConfirmeSale ? <ConfirmeSale sale={auxSale.sale} handleConfirmeSave={handleConfirmeSave} onClose={() => setShowViewAndConfirmeSale(false)} /> : null}
            {showConfirmSave ? <ConfirmationPopup title={titleConfirme}
                message={legendConfirme} onCancel={() => setShowConfirmSave(false)}
                onConfirm={isRestarSale ? handleRestartSaleNow : handleSave} /> : null}
            {isExpanded ? <GetExpandedImage nameProduct={nameProduct} linkImage={linkImage} onClose={toggleImageExpansion} /> : null}
            {statusFindWithCode ? <FindWithBarcode showValueSale={true} isBJ={isBJ} onValidator={onValidator} list={isBJ ? [...listItemsMemory] : [...listProductsMemory]} onClose={() => setStatusFindWithCode(false)} /> : null}
        </div>
    )
}

function GetData({ value }) {

    const [fixedValue, setFixedValue] = useState(auxSale.fixed)
    const [isPresent, setPresent] = useState(auxSale.typeSale !== 'NOVA VENDA')

    useEffect(() => {
        setPresent(auxSale.typeSale !== 'NOVA VENDA')
    }, [value])

    const handleUpdate = (e) => {
        const newValue = parseFloat(e.target.value)
        if (!isNaN(newValue)) {
            auxSale.updateFixedValue(newValue)
            setFixedValue(newValue)
        } else {
            auxSale.updateFixedValue(0)
            setFixedValue(0)
        }
    }

    return (
        <>
            <div className={style.divDataItems}>
                {auxSale.calculateMoneyFromSale.moneyTotal === 0 && auxSale.extraValue === 0 ?
                    <GetSmallModels backgroundColor={"#ff0000"} icon={faWarning} title={'Você Precisa Configurar a Operação...'} subtitle={'Ou Adcionar Peças/Produtos...'} />
                    : <TitlePageResponsiveIcon icon={faHandHoldingDollar} title={'DADOS TOTAIS'}
                        path={'É a Somatória Total da Operação...'} />}
                    {isPresent ? <GetSmallModels title={'É um Brinde'} /> :
                        <>
                            {auxSale.calculateMoneyFromSale.moneyTotal !== 0 && value && (
                                <InputModelsBlock
                                    legend={'FIXAR VALOR FINAL DA OPERAÇÃO'}
                                    iconButton={faMoneyBillTransfer}
                                    type={"number"}
                                    value={fixedValue}
                                    placeholder={"Valor que Você Deseja Vender..."}
                                    maxLength={10}
                                    onChange={handleUpdate}
                                />
                            )}
                            <Space top={5} />
                            {fixedValue === 0 ? 
                                <>
                                {auxSale.percentDiscont === 0 ? null : <GetSmallModels title={'Total Final com Desconto:'} subtitle={auxSale.calculateMoneyFromSale.getMoneyWithExtraToString() + ' * ' + auxSale.percentDiscont + '% = ' + auxSale.calculateMoneyFromSale.getValueDiscountToString()} value={auxSale.calculateMoneyFromSale.getMoneyTotalAllToString()} />}
                                {!auxSale.extraValue ? null : <GetSmallModels title={'Total Final Com Valor Extra:'} subtitle={auxSale.calculateMoneyFromSale.getHowCalculateExtra()} value={auxSale.calculateMoneyFromSale.getMoneyWithExtraToString()} />}
                                {auxSale.calculateMoneyFromSale.moneyTotal === 0 ? null : <GetSmallModels title={'Total Final Sem Descontos e Extras:'} subtitle={auxSale.calculateMoneyFromSale.getHowCalculateNormal()} value={auxSale.calculateMoneyFromSale.getMoneyTotalToString()} />}
                                {auxSale.calculateAllFromSale.itemsAll === 0 || !databaseSettings.isCanUseBrunaBJ? null : <GetSmallModels title={'Total de Peças:'} subtitle={auxSale.calculateAllFromSale.getHowCalculateItems()} value={auxSale.calculateAllFromSale.getItemsToString()} />}
                                {auxSale.calculateAllFromSale.modelsAll === 0 || !databaseSettings.isCanUseBrunaBJ? null : <GetSmallModels title={'Total de Modelos:'} subtitle={auxSale.calculateAllFromSale.getHowCalculateModels()} value={auxSale.calculateAllFromSale.getModelsToString()} />}
                            </> : null}
                        </>}
            </div>
            <Space top={5} />
            {auxSale.listItems.length === 0 || isPresent ? null :
                <div className={style.divDataItems}>
                    <TitlePageResponsiveIcon icon={faDatabase} title={'PEÇAS DA BRUNA SEMIJOIAS'}
                        path={'Dados Relacionados a Peças da Bruna Semijoias'} />
                    <GetSmallModels title={'Total Monetário (Bruna Semijoias) : '} subtitle={auxSale.val} value={auxSale.calculateMoneyFromSale.getMoneyItemsToString()} />
                    <GetSmallModels title={'Total de Modelos (Bruna Semijoias) :'} subtitle={auxSale.val} value={auxSale.calculateAllFromSale.calculateBJ.getItemsToString()} />
                    <GetSmallModels title={'Total de Peças (Bruna Semijoias) :'} subtitle={auxSale.val} value={auxSale.calculateAllFromSale.calculateBJ.getModelsToString()} />
                </div>}
            <Space top={5} />
            {auxSale.listProducts.length === 0 || isPresent ? null :
                <div className={style.divDataItems}>
                    <TitlePageResponsiveIcon icon={faProductHunt} title={'PRODUTOS GENÉRICOS'}
                        path={'Dados Relacionados aos Produtos Genéricos'} />
                    <GetSmallModels title={'Total Monetário (Produto Genéricos) : '} subtitle={auxSale.val} value={auxSale.calculateMoneyFromSale.getMoneyProductsToString()} />
                    <GetSmallModels title={'Total de Modelos (Produto Genéricos) :'} subtitle={auxSale.val} value={auxSale.calculateAllFromSale.calculateProduct.getItemsToString()} />
                    <GetSmallModels title={'Total de Peças (Produto Genéricos) :'} subtitle={auxSale.val} value={auxSale.calculateAllFromSale.calculateProduct.getModelsToString()} />
                </div>}
            <Space top={25} />
            <div className={style.divDataItems}>
                <GetSmallModels backgroundColor={"#054a00"} icon={faMoneyBillAlt} title={'VALOR TOTAl:'} 
                subtitle={'É o Valor Final'} value={auxSale.calculateMoneyFromSale.getMoneyTotalAllToString()} />
            </div>
        </>
    )
}

function ConfirmeSale({handleConfirmeSave, onClose }) {
    const [showGetPix,setShowGetPix] = useState(false)
    return (
        <div className={style.overlay}>
            <div className={style.alert}>
                {auxSale.client ?
                    <>
                        <TitlePageResponsiveIcon icon={faCheck} title={'VERIFICAR VENDA'}
                                path={'Verifique Tudo Antes de Finalizar...'}
                                children={
                                    <GetButton responsive={true} title={'GERAR PIX DE PAGAMENTO'} handleFunction={()=>setShowGetPix(true)} icon={faPix} color={"#ff0000"}/>
                                }
                            />
                        <GetDetailsConfirme/>
                        {auxSale.payForm === 'PARCELADO'? 
                        <>
                            <GetPaymentInSale/>
                            <Space top={5}/>
                        </>: null}
                    </>
                    : <GetSmallModels icon={faUserLargeSlash} title={'Você Precisa Configurar a Cliente'} />}
                <div className={style.buttons}>
                    {auxSale.client && (auxSale.calculateMoneyFromSale.moneyTotalAll !== 0) ? 
                    <GetButton title={"VENDER AGORA"} color='#00500b' icon={faSave} handleFunction={handleConfirmeSave} /> : null}
                    <Space left={5} top={5}/>
                    <GetButton title={"VOLTAR"} color='#E4080A' icon={faClose} handleFunction={onClose} />
                </div>

            </div>
            {showGetPix ? <GetPix valor={auxSale.calculateMoneyFromSale.moneyTotalAll} onClose={()=>setShowGetPix(false)}/> : null}
        </div>
    )
}

function GetPaymentInSale(){

    const [formData, setFormData] = useState({
        valuePayment:'',
        descriptionPayment:'',
        dateCalender:new Date().toISOString().slice(0, 10)
    })

    const [tempController] = useState(auxSale.controllerPayment)

    const [showAddPayment,setShowPaymentAdd] = useState(false)
    const [payment, setListPayment] = useState(auxSale.controllerPayment.paymentList)

    const handleRemove = (payment) => {
        auxSale.controllerPayment.paymentList = auxSale.controllerPayment.paymentList.filter(ss => ss !== payment)
        setListPayment(auxSale.controllerPayment.paymentList)
        tempController.calculate()
    }

    const handleAddNew = (payment) => {
        auxSale.controllerPayment.paymentList.push(payment)
        setListPayment(auxSale.controllerPayment.paymentList)
        tempController.calculate()
    }

    const handleChange = (e) => {
        if(e !== ''){
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value })
        }
    }

    return(
        <div className={style.divDataItems}>
            <div className={style.row}>
                <TitlePageResponsiveIcon title={'ADICIONAR PAGAMENTO'} icon={faMoneyBill1Wave}
                children={
                    <GetButtonIcon icon={faPlus} handleFunction={()=>setShowPaymentAdd(true)} color={"#037500"}/>
                }
                path={'É a Parcela de Entrada, ou o Valor já Pago'}/>
            </div>
            <Space top={5}/>
            <div className={style.row}>
                    <GetSmallModels 
                    title={'Total Pago: '}
                    value={tempController.getTotalPaidToString()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Falta Pagar: '}
                    value={tempController.getTotalNeedingPaymentToString()}/>
                    <Space left={5}/>
                    <GetSmallModels 
                    title={'Status: '} value={tempController.status}/>
            </div>
            <div className={style.divDataItems}>
            <GetAmountList amount={payment.length} title={'Pagamentos Registrados...'}/>
                {
                    payment.map(
                        (payment) => {
                        return <div className={style.rowPayment}>
                            <TitlePageResponsiveIcon title={payment.description} 
                            path={window.innerWidth> 768? 'Data do Pagamento: ' + payment.getDate(): payment.getDate()} 
                            subtitle={payment.getValueToString()}
                            children={
                                <GetButtonIcon icon={faRemove} height={40} width={40}
                                    handleFunction={()=>handleRemove(payment)} color={"#ff0000"}/>
                            }/>
                         
                        </div>
                    })
                }
           </div>
            {showAddPayment? 
            <AddPayment 
                handleAddNew={handleAddNew}
                formData={formData}
                handleChange={handleChange}
                handleClose={()=>setShowPaymentAdd(false)}/>
            : null}
        </div>
    )
}

export function GetDetailsConfirme(){
    return(
        <>
                <div className={style.divDataItems}>
                    <div className={style.client}>
                        {auxSale.client ? <ClientsModels onlyBasic={false} data={auxSale.client} /> : null}
                    </div>
                    <Space top={10} />
                    <GetSmallModels title={'Valor Extra:'} value={auxSale.getExtraValueToString()} />
                    <GetSmallModels title={'Tipo de Operação:'} value={auxSale.typeSale} />
                    <GetSmallModels title={'Data da Operação:'} value={auxSale.getDataToString()} />
                    <GetSmallModels title={'Forma de Pagamento:'} value={auxSale.payForm} />
                    {databaseSettings.isCanUseBrunaBJ?
                    <GetSmallModels title={'Fator de Multiplicação:'} value={auxSale.factor} />:null}
                    <GetSmallModels title={'Porcentagem de Desconto:'} value={auxSale.percentDiscont + '%'} />
                    <GetSmallModels title={'Registro no Sistema:'} value={auxSale.getDataRegisterToString()} />
                </div>
            <Space top={10} />
            <GetData />
            <Space top={10} />
        </>
    )
}

export default SaleGeneric